<template>
  <b-card
    v-if="Object.keys(representative).length"
    class="blog-edit-wrapper"
  >

    <!-- form -->
    <b-form class="mt-2" @submit.prevent="save">
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Imię"
            label-for="blog-edit-title"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-title"
              v-model="representative.firstname"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Nazwisko"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-slug"
              v-model="representative.lastname"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
            class="mr-1"
          >
            Zapisz zmiany
          </b-button>
          <b-button
            :to="{ name: 'apps-representatives' }"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
          >
            Anuluj
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BMediaAside, BMediaBody, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      representative: {
        id: 0,
        firstname: '',
        lastname: ''
      },
      snowOption: {
        theme: 'snow',
      },
    }
  },
  created() {
    const id = this.$route.params.id ?? 0
    if (id > 0) {
      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      axios.get(`${process.env.VUE_APP_API_URL}Representative/${id}`, config).then(response => {
        this.representative = response.data
      }).catch(error => {
        console.log(error)
      })
    }
  },
  methods: {
    save() {
      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } };
      axios.post(`${process.env.VUE_APP_API_URL}Representative`, this.representative, config).then(response => {
        this.$router.push({path: '/apps/representatives'})
      }).catch(error => {
      	var text = [];
      	for (const [key, value] of Object.entries(error.response.data.data)) {
		  if(value.length > 0) {
		  	text.push(value[0])
		  }
		}
        Swal.fire(
          text.join('<br/>'),
          '',
          'error',
        )
      })
    },
  },
  setup() {
    const refInputEl = ref(null)
    const refPreviewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { refPreviewEl.value.src = base64 })

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
